@tailwind base;
@tailwind components;
@tailwind utilities;

/* START : React Slider */
.slick-slide:not(.slick-center) {
    /* opacity: 0.5; */
    transform: scale(0.8); 
}
.slick-slide:not(.slick-center) .testimonail-card {
    background: #F9F9F9;
}
.slick-slide:not(.slick-center) .testimonail-card p {
    color: #C5C2CE;
}

/* END : React Slider */




@tailwind base;
@tailwind components;
@tailwind utilities;

/* START: react-data-table-component OVERRIDES  */
.custom-table .rdt_Table{
    padding: 8px;
    border-radius: 20px;
    @apply bg-gray-moon;
}

.custom-table .rdt_Table .rdt_TableHeadRow {
    border-radius: 20px;
    border: 0.5px solid;
    min-height: 38px;
    @apply border-star-light2 bg-star-light4 text-bodyThreeBold text-gray-6;
}

.custom-table .rdt_Table .rdt_TableBody .rdt_TableRow{
    border-bottom:none;
    @apply bg-gray-moon;
}
.custom-table .rdt_Pagination{
    border-top: none;
    background: transparent;
}
/* END: react-data-table-component OVERRIDES  */

/* START: Tool Tip */
.tooltip-wrapper {
    cursor: pointer;
    @apply relative;
}
.tooltip-wrapper:hover .tooltip{
    display: block;
}
/* END: Tool Tip */


/* START: Validator Button */

.validator-btn{
    @apply flex px-2 py-1 rounded-lg bg-star-light3 gap-2 cursor-pointer text-bodyThree text-gray-6 whitespace-nowrap;
}

.validator-btn svg{
    @apply text-venus-default;
}

.validator-btn:hover{
    @apply bg-venus-default text-white;
}

.validator-btn:hover svg{
    @apply text-white;
}

.validator-btn.checked svg{
    color: #21A700;
}
.validator-btn.checked:hover{
    background: #21A700;
}
.validator-btn.checked:hover svg{
    @apply  text-white;
}

/* END: Validator Button */

.font-inter{
    font-family: Inter;
}

.paragraph-spacing p{
    margin: 14px 0;
}

#copy-container p{
    margin: 14px 0;
}

/* #copy-container u{
    background-color: red;
} */